body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:disabled::placeholder {
  opacity: 0;
}

select:disabled,
input:disabled {
  background-color: rgba(0, 0, 0, 2%) !important;
  border-color: rgb(206 212 218) !important;
  background-image: none !important;
}

.form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

/* Make controls using isInvalid prop appear like normal controls until form is validated */
form:not(.was-validated) .form-control.is-invalid ~ .invalid-feedback {
  display: none;
}
form:not(.was-validated) .form-control.is-invalid {
  border-color: rgb(206 212 218) !important;
}
form:not(.was-validated) .form-control:focus.is-invalid {
  box-shadow: rgba(0, 123, 255, 0.247) 0px 0px 0px 3.13368px;
}

.was-validated .form-control:enabled.is-invalid {
  border-color: rgb(220, 53, 59) !important;
}

.was-validated .form-control:focus.is-invalid {
  box-shadow: rgba(220, 53, 59, 0.25) 0 0 0 3.2px !important;
}

.formText {
  font-size: 1rem !important;
}

.input-danger > input:disabled {
  color: #e74a3b;
}

input[type="checkbox"]:focus {
  outline: 3px solid rgba(0, 123, 255, 0.247);
}

.edit-form-label {
  font-weight: 700 !important;
}

.doc-not-received > input:disabled + label {
  color: #e74a3b !important;
}

.comment-red {
  color: red;
}
.comment-blue {
  color: blue;
}
.comment-green {
  color: green;
}

.button-group {
  font-size: 25px;
}
.edit-button:hover {
  color: #007bff;
  cursor: pointer;
}
.delete-button:hover {
  color: #dc3545;
  cursor: pointer;
}
.complete-button:hover {
  color: #007bff;
  cursor: pointer;
}

.sidebar {
  transition: 0.5s;
}

.dashboardTableCountButton {
  color: var(--primary);
  cursor: pointer;
}
.dashboardTableCountButton:hover {
  font-weight: bold;
}

.law-enforcement-row {
  border-top: 1px solid gainsboro;
  color: #5a5c69;
  cursor: pointer;
}
.law-enforcement-row:hover {
  background-color: gainsboro;
}

.navbar-nav .nav-link:hover {
  color: white !important;
}

.card-text {
  margin-left: 20px !important;
}

.requestButton {
  display: flex;
  flex-direction: row-reverse;
}

.inputWrapper {
  height: 50px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 1px dashed;
  border-radius: 4px;
  background-color: #fdfdfd;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 20px;
}
.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  /*This makes the button huge. If you want a bigger button, increase the font size*/
  font-size: 200px;
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
